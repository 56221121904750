import { Injectable, Injector } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaderResponse,
  HttpSentEvent,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { catchError, switchMap, filter, take, finalize } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
    return next.handle(this.addTokenToRequest(request, this.authService.getAuthToken())).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.error != undefined) {
            if (err.error.message != undefined && err.error.message != "") {
              //this.toastr.error(err.error.message);
            } else if (err.error !== "") {
              //this.toastr.error(err.error);
            }
          }

          switch ((<HttpErrorResponse>err).status) {
            case 401:
              return this.handle401Error(request, next);
            case 400:
              return <any>this.authService.signout();
          }
        } else {
          return throwError(err);
        }
      })
    );
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.tokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((user) => {
          if (user) {
            this.tokenSubject.next(user.accessToken);
            this.authService.currentUser = user;
            return next.handle(this.addTokenToRequest(request, user.accessToken));
          }
          return <any>this.authService.signout();
        }),
        catchError(err => {
          return <any>this.authService.signout();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addTokenToRequest(request, token));
        })
      );
    }
  }
}
